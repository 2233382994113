import styled from 'styled-components'
import { Media, fillBtnStyle, lineBorderBtnStyle } from '@raylink/common/theme'

export const BannerBtnsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const BtnContent = styled.a`
  ${({ type }) => (type === 'fill' ? fillBtnStyle : lineBorderBtnStyle)}
  width: 144px;
  height: 48px;
  font-size: 16px;
  ${Media.ipad`
    height: 44px;
  `}
  ${Media.phone`
    width: 2.4rem;
    height: 0.88rem;
    font-size: 0.32rem;
  `}
`
