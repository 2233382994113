import React from 'react'
import { BannerBtnsWrap, BtnContent } from './index.style'
import { Path } from '@raylink/common/constants/path'
import { jumpTo } from '@raylink/common/utils'

const GroupBtn = ({className}) => {
  const btns = [
    {
      title: '个人版下载',
      key: 'personal',
      type: 'fill',
      link: Path.download
    },
    {
      title: '企业版试用',
      key: 'business',
      type: 'line',
      link: 'https://enterprise-pre.raylink.live/login'
    }
  ]
  return (
    <BannerBtnsWrap className={className}>
      {btns.map((item, index) => (
        <BtnContent key={index} type={item.type} onClick={() => jumpTo(item.link)}>
          {item.title}
        </BtnContent>
      ))}
    </BannerBtnsWrap>
  )
}
export default GroupBtn
