import { Media } from '@raylink/common/theme'
import styled from 'styled-components'

export const BannerWrap = styled.div`
  width: 100%;
  height: 640px;
  min-width: 1200px;
  position: relative;
  .left-btn,
  .right-btn {
    position: absolute;
    top: 310px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    &:hover {
      path {
        opacity: 1;
      }
    }
  }
  .left-btn {
    left: 48px;
  }
  .right-btn {
    right: 48px;
  }
  .btn-list {
    position: absolute;
    left: calc(50% - 600px);
    bottom: 40px;
    z-index: 2;
  }
  ${Media.Biglaptop`
    .left-btn,
    .right-btn {
      display: none;
    }
  `}
  ${Media.ipad`
    height: 576px;
    min-width: 944px;
    .left-btn,
    .right-btn {
      display: none;
    }
    .btn-list {
      position: absolute;
      left: calc(50% - 472px);
    }
  `}
  ${Media.phone`
    height: 11.52rem;
    min-width: 100%;
    .btn-list {
      position: absolute;
      left: 0.32rem;
      bottom: -3.32rem;
    }
  `}
`
export const BannerContainer = styled.div`
  font-family: 'Source Han Sans CN';
  position: relative;
  width: 100%;
  height: 640px;
  display: flex;
  justify-content: center;
  .mobile-item {
    display: none;
  }
  .ipad-item {
    display: none;
  }
  ${Media.ipad`
    height: 576px;
    .mobile-item {
      display: none;
    }
    .web-item {
      display: none;
    }
    .ipad-item {
      display: block;
    }
  `}
  ${Media.phone`
    height: 11.52rem;
    .mobile-item {
      display: block;
    }
    .web-item {
      display: none;
    }
    .ipad-item {
      display: none;
    }
  `}
`

export const BannerImgBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  .web-item,
  .ipad-item,
  .mobile-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const BannerList = styled.div`
  position: relative;
  height: 100%;
`

export const BannerItem = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: ${({ show }) => (show ? 1 : 0)};
  z-index: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.7s;
`

export const ProgressWrap = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  left: calc(50% - 595px);
  bottom: 80px;
  z-index: 2;
  ${Media.ipad`
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  `}
  ${Media.phone`
    justify-content: center;
    bottom: 0.42rem;
    left: 50%;
    transform: translateX(-50%);
  `}
`

export const ProgressItem = styled.div`
  width: 32px;
  height: 4px;
  background: #d9d9d9;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  ${({ active, progress }) =>
    active
      ? `
  &::after {
    content: '';
    display: block;
    width: ${progress}%;
    height: 100%;
    background: #595959;
  }
  `
      : ''};
  ${Media.phone`
    width: 0.8rem;
    height: 0.08rem;
  `}
`

export const SwitchBtn = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`
