import React from 'react'
import { Layout } from '@raylink/common/components'
// import Feature from '../../components/home/Feature'
// import Scenes from '../../components/home/Scenes'
// import ColorModel from '../../components/home/ColorModel'
// import Fps from '../../components/home/Fps'
import InfoShare from '../../components/home/InfoShare'
import ModalInfo from '../../components/home/ModalInfo'
import Banner from '../../components/home/banner'
// import LowDelay from '../../components/home/lowDelay'
import DownloadBar from '../../components/home/downloadBar'
// import Comment from '../../components/home/comment'
import { useStaticQuery, graphql } from 'gatsby'
import DataShow from '../../components/home/DataShow'
import TechnologyIntro from '../../components/home/TechnologyIntro'
import FunctionIntro from '../../components/home/FunctionIntro'
import BusinessIntro from '../../components/home/BusinessIntro'
import RiskPreventionIntro from '../../components/home/RiskPreventionIntro'
import SceneIntro from '../../components/home/SceneIntro'
import { Accept } from '../product/business/component/accept'

const Home = () => {
  const { allContentfulFriendLink } = useStaticQuery(graphql`
    query {
      allContentfulFriendLink(sort: { fields: rank, order: DESC }) {
        edges {
          node {
            id
            name
            url
          }
        }
      }
    }
  `)

  return (
    <Layout pageType="home" friendLink={allContentfulFriendLink.edges || []}>
      <Banner />
      <DataShow />
      <TechnologyIntro />
      <FunctionIntro />
      <BusinessIntro />
      <RiskPreventionIntro />
      <SceneIntro />
      <Accept />
      {/* <ColorModel /> */}
      {/* <Fps /> */}
      {/* <LowDelay /> */}
      {/* <Feature /> */}
      {/* <Scenes /> */}
      {/* <Comment /> */}
      <InfoShare />
      {/* <Contactbar /> */}
      <ModalInfo />
      <DownloadBar />
    </Layout>
  )
}

export default Home
