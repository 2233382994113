import { Media } from '@raylink/common/theme'
import { NewWrapper } from '@raylink/common/components'
import styled from 'styled-components'
import { color, typography } from '@raylink/common//theme/variables'

export const InfoShareWarpper = styled(NewWrapper)`
  margin-top: 0;
  ${Media.phone`
    margin-top: 0.2rem;
  `}
`

export const ItemBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 54px;
  ${Media.ipad`
    margin-top: 38px;
  `}
  ${Media.phone`
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0.6rem;
  `}
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  width: 378px;
  border-radius: 24px;
  cursor: pointer;
  padding: 6px 6px 40px 6px;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  :hover {
    box-shadow: 0px 20px 32px 0px rgba(204, 204, 204, 0.16);
    transform: translateY(-8px);
  }
  ${Media.ipad`
    width: 304px;
    border-radius: 12px;
    padding: 6px 6px 32px 6px;
  `}
  ${Media.phone`
    width: 6.86rem;
    margin-bottom: 0.12rem;
    border-radius: 0.24rem;
    padding: 0.12rem 0.12rem 0.44rem 0.12rem;
  `}
`
export const ItemTitle = styled.a`
  font-size: ${typography.h4};
  font-weight: 500;
  line-height: 24px;
  color: ${color.textBlack};
  padding: 24px 18px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  :hover {
    color: ${color.primary};
  }
  ${Media.ipad`
    padding: 20px 20px 0;
  `}
  ${Media.phone`
    font-size: 0.32rem;
    line-height: 0.48rem;
    padding: 0.48rem 0.48rem 0;
  `}
`
export const ItemContent = styled.div`
  font-size: ${typography.textSmall};
  color: ${color.text};
  line-height: 21px;
  padding: 8px 18px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  ${Media.ipad`
    padding: 8px 24px 0;
    -webkit-line-clamp: 2;
  `}
  ${Media.phone`
    font-size: 0.28rem;
    line-height: 0.42rem;
    padding: 0.16rem 0.48rem 0;
  `}
`

export const ItemTime = styled.span`
  color: ${color.textLight};
`

export const ItemMore = styled.a`
  color: ${color.primary};
`
export const ItemMain = styled.div`
  display: flex;
  flex-direction: column;
`
export const ItemCover = styled.a`
  display: inline-block;
  height: 213px;
  width: 100%;
  overflow: hidden;
  .coverImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  :hover {
    cursor: pointer;
  }
  ${Media.ipad`
    height: 159px;
    .coverImage {
      border-radius: 16px;
    }
  `}
  ${Media.phone`
    height: 3.72rem;
    .coverImage {
      border-radius: 0.32rem;
    }
  `}
`
