import { NewWrapper } from '@raylink/common/components'
import { fillBtnStyle, Media } from '@raylink/common/theme'
import styled from 'styled-components'

export const SchoolContentWrapper = styled(NewWrapper)`
  position: absolute;
  top: 130px;
  display: flex;
  flex-direction: column;
  align-items: start;
  .main-title {
    width: 506.9px;
    height: 270.61px;
    margin-left: -44px;
  }
  .main-title-mobile {
    display: none;
  }
  ${Media.ipad`
    .main-title {
      width: 460px;
      height: 245.57px;
      margin-left: -41px;
    }
  `}
  ${Media.phone`
    align-items: center;
    top: 1.28rem;
    .main-title {
      display: none;
    }
    .main-title-mobile {
      display: block;
      width: 6.82rem;
      height: 3.8rem;
    }
  `}
`
export const BuyButton = styled.a`
  ${fillBtnStyle}
  font-size: 18px;
  width: 140px;
  height: 46px;
  margin-top: 39.98px;
  border-radius: 6px;
  background: linear-gradient(90deg, #11c871 0%, #11c871 100%);
  box-shadow: 0px -4px 7.8px 0px rgba(255, 255, 255, 0.4) inset,
    0px 4px 6.4px 0px rgba(255, 255, 255, 0.4) inset;
  cursor: pointer;
  &:hover {
    border: 1px solid white;
    width: 139px;
    height: 45px;
  }
  ${Media.phone`
    width: 2.24rem;
    height: 0.8rem;
    border-radius: 0.12rem;
    font-size: 0.28rem;
    margin-top: 0.36rem;
  `}
`
