import React from 'react'
import { BannerContainer, BannerImgBox } from '../atom'
import { BannerContentWrapper, H1, H3, Left, BewareWrap } from './index.style'
import bannerImg from '../../../../images/home/banner/fraudBg.png'
import bannerPadImg from '../../../../images/home/banner/fraudPadBg.png'
import fraudMobileBg from '../../../../images/home/banner/fraudMobileBg.png'

const FraudBanner = () => {
  return (
    <BannerContainer>
      <BannerImgBox>
        <img
          className="web-item"
          src={bannerImg}
          alt="RayLink远程控制软件"
          loading="lazy"
        />
        <img
          className="ipad-item"
          src={bannerPadImg}
          alt="RayLink远程控制软件"
          loading="lazy"
        />
        <img
          className="mobile-item"
          src={fraudMobileBg}
          alt="RayLink远程控制软件"
          loading="lazy"
        />
      </BannerImgBox>
      <BannerContentWrapper>
        <Left>
          <BewareWrap>-谨防网络诈骗-</BewareWrap>
          <H1>拒绝陌生远控请求</H1>
          <H3>
            若对方自称是保险公司或政府单位的工作人员，切勿轻信，请拒绝对方远程控制您的手机和电脑，谨防诈骗！
          </H3>
        </Left>
      </BannerContentWrapper>
    </BannerContainer>
  )
}

export default FraudBanner
