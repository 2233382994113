import styled from 'styled-components'
import { color } from '@raylink/common//theme/variables'
import { Media } from '@raylink/common/theme'

export const H2 = styled.h2`
  position: relative;
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: ${color.textBlack};
  margin: 0;
  z-index: 1;

  ${Media.phone`
    font-size: 0.56rem;
    line-height: 0.72rem;
  `}
`

export const H3 = styled.h3`
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: ${color.textDark};
  margin: 6px 0 0 0;
  ${Media.phone`
    font-size:  0.32rem;
    line-height: 0.48rem;
    width: 4.6rem;
    padding: 0 0.1rem;
    margin-top: 0.08rem;
  `}
`
