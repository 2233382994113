import React from 'react'
import { BannerContainer, BannerImgBox } from './atom'

const BasicBanner = ({
  webImg,
  ipadImg,
  mobileImg,
  alt = 'RayLink远控',
  children,
}) => {
  return (
    <BannerContainer>
      <BannerImgBox>
        <img className="web-item" src={webImg} alt={alt} loading="lazy" />
        <img className="ipad-item" src={ipadImg} alt={alt} loading="lazy" />
        <img className="mobile-item" src={mobileImg} alt={alt} loading="lazy" />
      </BannerImgBox>
      {children}
    </BannerContainer>
  )
}

export default BasicBanner
