import styled from 'styled-components'
import { Media } from '@raylink/common/theme'
import { NewWrapper } from '@raylink/common/components'

export const BannerContainer = styled.div`
  font-family: 'Source Han Sans CN';
  position: relative;
  width: 100%;
  height: 720px;
  .mobile-item,
  .ipad-item {
    display: none;
  }
  ${Media.ipad`
    height: 680px;
    .ipad-item {
      display: block;
    }
  `}
  ${Media.phone`
    height: 12.48rem;
    .mobile-item {
      display: block;
    }
    .web-item, .ipad-item {
      display: none;
    }
  `}
`
export const BannerImgBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const BannerContentWrapper = styled(NewWrapper)`
  display: flex;
  justify-content: start;
  ${Media.phone`
    flex-direction: column;
    align-items: center;
  `}
`

export const BewareWrap = styled.div`
  width: 212px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-radius: 38px;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 500;
  color: #5989dc;
  ${Media.phone`
    width: 3.4rem;
    padding: 0.16rem 0;
    border-radius: 0.76rem;
    margin-bottom: 0.24rem;
    font-size: 0.36rem;
  `}
`

export const H1 = styled.div`
  font-size: 56px;
  color: #fff;
  font-weight: 700;
  line-height: 1.4;
  margin: 0;
  ${Media.ipad`
    font-size: 48px;
  `}
  ${Media.phone`
    font-size: 0.72rem;
    text-align: center;
  `}
`
export const H3 = styled.h3`
  width: 460px;
  font-size: 18px;
  color: #fff;
  line-height: 1.5;
  margin: 8px 0 0 0;
  font-weight: 400;
  ${Media.ipad`
    width: 390px;
    font-size: 16px;
  `}
  ${Media.phone`
    width: 6.4rem;
    font-size: 0.32rem;
    line-height: 0.48rem;
    text-align: center;
    margin: 0.12rem 0 0 0;
  `}
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 196px;
  color: #212121;
  ${Media.ipad`
    margin-top: 181px;
    padding-left: 0;
  `}
  ${Media.phone`
    width: 6.46rem;
    margin-top: 1.8rem;
    align-items: center;
    .btns {
      margin: 0.32rem auto 0.64rem;
    }
  `}
`
