import React from 'react'
import { BannerContainer, BannerImgBox } from '../atom'
import { BannerContentWrapper, Left, Right, H1 } from './index.style'
import { useMedia } from '@raylink/common/hooks'
import GroupBtn from './components/groupBtn'
import mainImg from '../../../../images/home/banner/remote/mainImg.webp'
import bannerImg from '../../../../images/home/banner/remote/bannerImg.webp'
import bannerPadImg from '../../../../images/home/banner/remote/bannerPadImg.webp'
import bannerMobileImg from '../../../../images/home/banner/remote/bannerMobileImg.webp'

const RemoteBanner = () => {
  const isMobile = useMedia()
  const bannerInfo = {
    title: 'RayLink远程控制',
    v1: '远控实力派',
    v2: '企业个人双优选',
    description: `安全铸就信任，性能驱动卓越，全方位满足个人与企业的远程需求${
      !isMobile ? '，开启一段真高清、超流畅的远程控制之旅' : ''
    }`,
    bannerImg,
    bannerPadImg,
    bannerMobileImg,
  }

  return (
    <BannerContainer>
      <BannerImgBox>
        <img
          className="web-item"
          src={bannerInfo.bannerImg}
          alt={bannerInfo.title}
          loading="lazy"
        />
        <img
          className="ipad-item"
          src={bannerInfo.bannerPadImg}
          alt={bannerInfo.title}
          loading="lazy"
        />
        <img
          className="mobile-item"
          src={bannerInfo.bannerMobileImg}
          alt={bannerInfo.title}
          loading="lazy"
        />
      </BannerImgBox>
      <BannerContentWrapper>
        <Left>
          <p className="title">{bannerInfo.title}</p>
          <H1>{bannerInfo.v1}</H1>
          <H1>{bannerInfo.v2}</H1>
          <span className="description">{bannerInfo.description}</span>
          <GroupBtn className="button-list" />
        </Left>
        <Right>
          <img className="img" src={mainImg} alt={bannerInfo.title} />
        </Right>
      </BannerContentWrapper>
    </BannerContainer>
  )
}

export default RemoteBanner
