import React from 'react'
import { RiskPreventionIntroWrapper, ContentWrapper } from './index.style'
import EncryptIcon from './images/encrypt.png'
import EncryptMobileIcon from './images/encryptMobile.png'
import StandardIcon from './images/standard.png'
import StandardMobileIcon from './images/standardMobile.png'
import SecurityIcon from './images/security.png'
import SecurityMobileIcon from './images/securityMobile.png'

const RiskPreventionIntro = () => {
  const data = [
    {
      title: '加密协议',
      desc: '采用金融级AES-256端到端安全加密传输协议，杜绝数据风险',
      img: EncryptIcon,
      mobileImg: EncryptMobileIcon,
    },
    {
      title: '标准合规',
      desc: '获国际安全管理体系标准 ISO27001认证，严守安全合规标准',
      img: StandardIcon,
      mobileImg: StandardMobileIcon,
    },
    {
      title: '安全防护',
      desc: '远控全流程部署多层次安全防护，守护信息机密，严防电信诈骗',
      img: SecurityIcon,
      mobileImg: SecurityMobileIcon,
    },
  ]
  return (
    <RiskPreventionIntroWrapper>
      <h2>严密合规，防范风险</h2>
      <ContentWrapper>
        {data.map((item, index) => (
          <div key={index} className="content-item">
            <img
              className="normal-img"
              src={item.img}
              alt={item.title}
              loading="lazy"
            />
            <img
              className="mobile-img"
              src={item.mobileImg}
              alt={item.title}
              loading="lazy"
            />
            <div className="desc-info">
              <h3>{item.title}</h3>
              <p>{item.desc}</p>
            </div>
          </div>
        ))}
      </ContentWrapper>
    </RiskPreventionIntroWrapper>
  )
}

export default RiskPreventionIntro
