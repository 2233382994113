import React from 'react'
import {
  BusinessIntroWrapper,
  ContentWrapper,
  BtnWrapper,
  Button,
} from './index.style'
import RightOutlined from '../FunctionIntro/images/RightArrow'
import PointsImg from './images/right.png'
import { useMedia } from '@raylink/common/hooks'
import { jumpTo } from '@raylink/common/utils'
import { Path } from '@raylink/common/constants/path'
import TeamImg from '../../../images/home/businessIntro/team.webp'
import TeamIpadImg from '../../../images/home/businessIntro/teamIpad.webp'
import TeamMobileImg from '../../../images/home/businessIntro/teamMobile.webp'
import SecurityImg from '../../../images/home/businessIntro/security.webp'
import SecurityIpadImg from '../../../images/home/businessIntro/securityIpad.webp'
import SecurityMobileImg from '../../../images/home/businessIntro/securityMobile.webp'
import DeployImg from '../../../images/home/businessIntro/deploy.webp'
import DeployIpadImg from '../../../images/home/businessIntro/deployIpad.webp'
import DeployMobileImg from '../../../images/home/businessIntro/deployMobile.webp'

const BusinessIntro = () => {
  const title = ['企业版', '高效管理，安全远控']
  const isMobile = useMedia()
  const teamDeviceData = [
    {
      title: '灵活团队设备管理',
      desc: '灵活管理、配置、监控成员账号、角色权限与连接设备，提高团队协作效率',
      points: [
        '自定义组织架构',
        '企业用户管理',
        '成员角色管理',
        '设备权限管理',
        '操作权限设置',
        '管理员权限设置',
      ],
      bgImg: TeamImg,
      bgImgIpad: TeamIpadImg,
      bgImgMobile: TeamMobileImg,
    },
    {
      title: '全方位安全管控',
      desc: '内置全面的安全管理与审计机制，防范风险，远控留痕可追溯',
      points: ['文件传输记录', '控制台日志', '双因子验证', '远程日志'],
      bgImg: SecurityImg,
      bgImgIpad: SecurityIpadImg,
      bgImgMobile: SecurityMobileImg,
    },
    {
      title: '轻量级高效部署',
      desc: '采用轻量级部署模式，兼容性高，减少对接时间与资源消耗',
      points: ['AD域批量导入', '批量部署设备'],
      bgImg: DeployImg,
      bgImgIpad: DeployIpadImg,
      bgImgMobile: DeployMobileImg,
    },
  ]
  const btnList = [
    {
      name: '咨询企业版产品',
      type: 'fill',
      link: Path.contact,
    },
    {
      name: '了解私有化服务',
      type: 'line',
      link: Path.private,
    },
  ]
  return (
    <BusinessIntroWrapper>
      {isMobile ? (
        title.map((item, index) => <h2 key={index}>{item}</h2>)
      ) : (
        <h2>{title.join('·')}</h2>
      )}
      <div className="desc-text" onClick={() => jumpTo(Path.businessProduct)}>
        <span>了解更多企业版产品详情</span>
        <RightOutlined className="img" />
      </div>
      <ContentWrapper>
        {teamDeviceData.map((item, index) => (
          <div className={`content-item item${index + 1}`} key={index}>
            <img
              className="bg img"
              src={item.bgImg}
              alt={item.title}
              loading="lazy"
            />
            <img
              className="bg-ipad img"
              src={item.bgImgIpad}
              alt={item.title}
              loading="lazy"
            />
            <img
              className="bg-mobile img"
              src={item.bgImgMobile}
              alt={item.title}
              loading="lazy"
            />
            <div className="desc-info">
              <h3>{item.title}</h3>
              <p className="desc">{item.desc}</p>
              <ul>
                {item.points.map((point, i) => (
                  <li key={i}>
                    <img src={PointsImg} alt="✅" loading="lazy" />
                    <span>{point}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </ContentWrapper>
      <BtnWrapper>
        {btnList.map((btn, index) => (
          <Button key={index} type={btn.type} href={btn.link}>
            {btn.name}
          </Button>
        ))}
      </BtnWrapper>
    </BusinessIntroWrapper>
  )
}

export default BusinessIntro
