import { Warpper } from '@raylink/common/components'
import { color, Media } from '@raylink/common/theme'
import styled from 'styled-components'
import { IndexWrapper } from '../../../view/home/index.style'

export const TechnologyIntroWrapper = styled(Warpper)`
  ${IndexWrapper}
  .tabs {
    display: flex;
    flex-direction: row;
    width: 577px;
    justify-content: space-between;
    margin-top: 32px;
    padding: 0 20px;
    position: relative;
    .tab-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 3px;
      cursor: pointer;
      img {
        width: 48px;
        height: 48px;
      }
      p {
        font-size: 24px;
        font-weight: 500;
      }
      &:hover,
      &.active {
        color: ${color.primary};
      }
    }
  }
  .active-border {
    height: 3px;
    width: 171px;
    background-color: ${color.primary};
    margin-top: 10px;
    border-radius: 2px;
    transform: ${({ activeIndex }) => `translateX(${activeIndex * 203}px)`};
    transition: transform 0.3s;
  }
  .line {
    position: absolute;
    height: 1px;
    width: 577px;
    background-color: rgba(192, 192, 192, 0.3);
  }
  ${Media.phone`
    .tabs {
      width: 6.58rem;
      justify-content: space-between;
      padding: 0;
      .tab-item {
        width: 1.9rem;
        font-size: 0.38rem;
        white-space: nowrap;
        img {
          width: 0.64rem;
          height: 0.64rem;
        }
      }
    }
    .active-border {
      width: 1.9rem;
      transform: ${({ activeIndex }) => `translateX(${activeIndex * 2.4}rem)`};
    }
    .line {
      width: 6.58rem;
    }
  `}
`

export const VideoWrapper = styled.div`
  width: 1080px;
  height: 608px;
  border-radius: 20px;
  margin-top: 56px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  border: 2px solid #f6f6f6;
  .video-item {
    width: 1080px;
    height: 608px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: ${({ activeIndex }) => `translateX(-${activeIndex * 1080}px)`};
    transition: transform 0.3s;
    video {
      width: 1080px;
      height: 608px;
    }
    .video-text {
      position: absolute;
      top: 57px;
      left: 20px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(10px);
      border-radius: 8px;
      padding: 12px 20px;
      span {
        display: block;
        font-size: 24px;
        font-weight: 500;
        font-family: Source Han Sans CN;
        color: #fff;
        & + span {
          margin-top: 2px;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
  ${Media.ipad`
    width: 928px;
    height: 522px;
  `}
  ${Media.phone`
    width: 6.86rem;
    height: fit-content;
    margin: 0;
    border-radius: 0;
    border: none;
    .video-item {
      width: 6.86rem;
      height: fit-content;
      position: static;
      transform: ${({ activeIndex }) =>
        `translateX(-${activeIndex * 6.86}rem)`};
      video {
        width: 6.86rem;
        height: 3.86rem;
        border-radius: 0.12rem;
      }
      .video-text {
        position: static;
        padding: 0;
        text-align: center;
        line-height: 0.48rem;
        margin: 0.36rem 0;
        .title {
          color: #333;
          font-size: 0.32rem;
          font-weight: 700;
        }
        .desc {
          color: ${color.textGray};
          font-size: 0.32rem;
          font-weight: 400;
        }
      }
    }
  `}
`
