import React from 'react'
import { DataWrap } from './index.style'
import UserIcon from './images/user.png'
import DeviceIcon from './images/device.png'
import RegionIcon from './images/region.png'

const DataShow = () => {
  const data = [
    {
      name: '用户',
      value: 280,
      unit: '万+',
      desc: '深受远控用户信赖',
      icon: UserIcon,
    },
    {
      name: '连接设备',
      value: 750,
      unit: '万+',
      desc: '提供稳定、优质连接线路',
      icon: DeviceIcon,
    },
    {
      name: '国家地区',
      value: 200,
      unit: '个+',
      desc: '满足境内、跨境远程需求',
      icon: RegionIcon,
    },
  ]
  return (
    <DataWrap>
      {data.map((item, index) => (
        <div className="data-item" key={index}>
          <img src={item.icon} alt={item.name} loading="lazy" />
          <div className="right-text">
            <div className="top-info">
              <div>
                <span className="value">{item.value}</span>
                <span className="unit">{item.unit}</span>
              </div>
              <span>{item.name}</span>
            </div>
            <div className="right-desc">{item.desc}</div>
          </div>
        </div>
      ))}
    </DataWrap>
  )
}

export default DataShow
