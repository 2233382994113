import styled from 'styled-components'
import { Media } from '@raylink/common/theme'

export const QrCardWrap = styled.div`
  width: fit-content;
  padding: 5px 5px 9px;
  border-radius: 10px;
  font-size: 14px;
  color: #878787;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(3, 102, 52, 0.1);
  text-align: center;
  img {
    width: 134px;
  }
  .qrMore {
    margin: 0 0 6px;
  }
  .qr-text-mobile {
    display: none;
  }
  ${Media.phone`
    padding: 0.14rem 0.14rem 0.46rem;
    border-radius: 0.2rem;
    font-size: 0.32rem;
    img {
      width: 3.72rem;
    }
    .qrMore {
      margin: 0 0 0.08rem;
    }
    .qr-text-web {
      display: none;
    }
    .qr-text-mobile {
      display: block;
    }
  `}
`
