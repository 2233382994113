import React, { useState } from 'react'
import { useMedia } from '@raylink/common/hooks'
import QrCard from './component/qrCard'
import { BannerContainer } from '../atom'
import {
  BannerContentWrapper,
  H1,
  ReasonsList,
  ReasonsWrapper,
  ReasonsItem,
  Left,
  QrWrapper,
  BannerImgBox,
  Right,
} from './index.style'
import bannerImg from '../../../../images/home/banner/agentBg.png'
// import bannerPadImg from '../../../../images/home/banner/agentPadBg.png'
import mobileBannerImg from '../../../../images/home/banner/agentMobileBg.png'
import clickSvg from '../../../../images/home/banner/click.svg'
import qrCode from '../../../../images/home/banner/agentQr.png'
import QrModal from './component/qrModal'

const reasonList = [
  {
    text: '低成本、高收益',
    icon: clickSvg,
  },
  {
    text: '形式灵活、高效返佣',
    icon: clickSvg,
  },
  {
    text: '不限区域、畅享全球市场',
    icon: clickSvg,
  },
  {
    text: '专业辅导、强力支持',
    icon: clickSvg,
  },
]

const AgentBanner = () => {
  const isMobile = useMedia()
  const [open, setOpen] = useState(false)

  return (
    <BannerContainer>
      <BannerImgBox>
        <img
          className="mobile-item"
          src={mobileBannerImg}
          alt="RayLink代理商火热招募中"
          loading="lazy"
        />
      </BannerImgBox>
      <BannerContentWrapper>
        <Left>
          <H1>RayLink代理商火热招募中</H1>
          <H1 className="last-title">
            邀您共享<span>全球百亿市场</span>
          </H1>
          <ReasonsWrapper>
            <ReasonsList>
              {reasonList.map((item, index) => (
                <ReasonsItem key={index}>
                  <img src={item.icon} alt={item.text} loading="lazy" />
                  {item.text}
                </ReasonsItem>
              ))}
            </ReasonsList>
            <QrWrapper>
              <img
                src={qrCode}
                alt="QR Code"
                loading="lazy"
                className="qrCodeImg"
                onClick={() => {
                  if (!isMobile) return
                  setOpen(true)
                }}
              />
              <QrCard className="card" />
            </QrWrapper>
          </ReasonsWrapper>
        </Left>
        <Right>
          <img src={bannerImg} alt="RayLink代理商火热招募中" loading="lazy" />
        </Right>
      </BannerContentWrapper>
      {isMobile && open && <QrModal setOpen={setOpen} />}
    </BannerContainer>
  )
}

export default AgentBanner
