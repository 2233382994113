import { NewWrapper } from '@raylink/common/components'
import { color, Media } from '@raylink/common/theme'
import styled from 'styled-components'

export const DataWrap = styled(NewWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  gap: 156px;
  height: 96px;
  .data-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    img {
      width: 72px;
      height: 72px;
    }
    .right-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      .top-info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        font-size: 16px;
        color: ${color.textBlack};
        div {
          display: flex;
          align-items: flex-end;
          .value {
            height: 36px;
            font-family: 'D-DIN-PRO';
            font-size: 38px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .unit {
            margin-right: 6px;
          }
        }
        span {
          font-weight: 700;
          color: ${color.textBlack};
        }
      }
      .right-desc {
        font-size: 16px;
        font-weight: 400;
        color: ${color.textBlack};
        font-family: Source Han Sans CN;
        letter-spacing: 1px;
      }
    }
  }
  ${Media.ipad`
    height: 92px;
    gap: 72px;
    .data-item {
      .right-text {
        .top-info {
          .value {
            font-size: 36px;
          }
        }
      }
    }
  `}
  ${Media.phone`
    height: 1.3rem;
    gap: 0.8rem;
    .data-item {
      img, .right-text .right-desc{
        display: none;
      }
      .right-text {
        .top-info {
          flex-direction: column;
          align-items: center;
          div {
            margin-bottom: 0.12rem;
            .value {
              font-size: 0.56rem;
              line-height: 0.6rem;
              height: 0.54rem;
            }
            .unit {
              margin-right: 0;
            }
            span {
              font-size: 0.32rem;
            }
          }
          > span {
            font-size: 0.26rem;
            font-weight: 400;
          }
        }
      }
    }
  `}
`
