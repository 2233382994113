import React, { useEffect, useRef, useState } from 'react'
import { FunctionIntroWrapper, ContentWrapper } from './index.style'
// import RightOutlined from './images/RightArrow'
import GroupBtn from '../banner/remoteBanner/components/groupBtn'
import FileTransferImg from '../../../images/home/functionIntro/fileTransfer.webp'
import FileTransferMobileImg from '../../../images/home/functionIntro/fileTransferMobile.webp'
import MultiScreenImg from '../../../images/home/functionIntro/multiScreen.webp'
import MultiScreenMobileImg from '../../../images/home/functionIntro/multiScreenMobile.webp'
import PrivacyImg from '../../../images/home/functionIntro/privacy.webp'
import PrivacyMobileImg from '../../../images/home/functionIntro/privacyMobile.webp'
import RemoteImg from '../../../images/home/functionIntro/remote.webp'
import RemoteMobileImg from '../../../images/home/functionIntro/remoteMobile.webp'
import FeatureBg from './images/feature_bg.png'
import RightImg from './images/rightBg.png'
import { useMedia } from '@raylink/common/hooks'

const FunctionIntro = () => {
  const data = [
    {
      title: '文件传输',
      desc: '搭载自研传输协议，跨端不限数量、格式、大小传输文件',
      img: FileTransferImg,
      mobileImg: FileTransferMobileImg,
    },
    {
      title: '多屏控制',
      desc: '一对多、多对多屏幕远控，多线程处理工作更高效',
      img: MultiScreenImg,
      mobileImg: MultiScreenMobileImg,
    },
    {
      title: '防窥模式',
      desc: '受控端隐藏远程操作与活动，避免被恶意窥探',
      img: PrivacyImg,
      mobileImg: PrivacyMobileImg,
    },
    {
      title: '移动远控',
      desc: '用手机、平板发起或接受远控，工作娱乐都便捷',
      img: RemoteImg,
      mobileImg: RemoteMobileImg,
    },
  ]
  const isMobile = useMedia()
  const [activeIndex, setActiveIndex] = useState(0)
  const timer = useRef(null)
  const switchTime = 5000
  const startTimer = () => {
    timer.current = setInterval(() => {
      setActiveIndex(pre => (pre === data.length - 1 ? 0 : pre + 1))
    }, switchTime)
  }
  const switchCard = index => {
    clearInterval(timer.current)
    setActiveIndex(index)
    startTimer()
  }
  useEffect(() => {
    startTimer()
    return () => {
      clearInterval(timer.current)
    }
  }, [])
  return (
    <FunctionIntroWrapper>
      <div className="bg-img">
        <img src={FeatureBg} alt="" loading="lazy" />
      </div>
      <h2>功能强大，快速上手</h2>
      {/* 暂时隐藏 */}
      {/* <div className="desc-text">
        <span>发现更多远控功能</span>
        <RightOutlined className="img" />
      </div> */}
      <ContentWrapper>
        <div>
          {data.map((item, index) => (
            <div
              className={`card-item ${index === activeIndex ? 'active' : ''}`}
              key={index}
              onClick={() => switchCard(index)}
            >
              <h3 className="title">{item.title}</h3>
              {index === activeIndex && (
                <>
                  <div className="description">{item.desc}</div>
                  {isMobile ? (
                    <img
                      className="item-img"
                      src={data[activeIndex].mobileImg}
                      alt={data[activeIndex].title}
                      loading="lazy"
                    />
                  ) : null}
                  <img
                    className="item-bg"
                    src={RightImg}
                    alt=""
                    loading="lazy"
                  />
                </>
              )}
            </div>
          ))}
        </div>
        {!isMobile && (
          <img
            className="img"
            src={data[activeIndex].img}
            alt={data[activeIndex].title}
            loading="lazy"
          />
        )}
      </ContentWrapper>
      <GroupBtn />
    </FunctionIntroWrapper>
  )
}

export default FunctionIntro
