import { Media } from '@raylink/common/theme'
import styled from 'styled-components'
import { typography } from '@raylink/common//theme/variables'

export const WechatWrapper = styled.div`
  display: none;
  position: absolute;
  top: 98px;
  left: 259px;
  width: 160px;
  transform: translate(-100%, -50%);
  padding: 4px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  text-align: center;
  .product-img {
    height: 140px;
    width: 140px;
  }
  span {
    display: block;
    font-size: ${typography.textThin};
    line-height: 18px;
    color: #7c7c7c;
  }
`

export const UpgradeModalContent = styled.div`
  width: 702px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 702px;
    height: 221px;
    margin-top: -1px;
  }
  img:nth-child(2) {
    display: none;
  }
  .btn {
    height: 40px;
    width: 160px;
    margin: 30px 0 40px;
  }
  > div {
    color: #002b36;
    line-height: 24px;
    padding: 12px 50px 0;
    > p {
      > strong {
        color: #11c871;
      }
    }
  }
  .phone {
    margin-bottom: 40px;
    position: relative;
    .product-show {
      cursor: pointer;
    }
    .product-show:hover {
      ${WechatWrapper} {
        display: block;
      }
    }
  }
  ${Media.phone`
    width: 335px;
    font-size: 12px;
    img:nth-child(1) {
      display: none;
    }
    img:nth-child(2) {
      display: block;
      width: 335px;
      height: 131px;
    }
    .btn {
      height: 25px;
      width: 100px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      margin: 20px 0 30px;
    }
    > div {
      padding: 0 22px;
    }
    .phone {
      margin-bottom: 20px;
    }
  `}
`
export const Divider = styled.hr`
  border: none;
  height: 1px;
  margin: 0;
  flex-shrink: 0;
`

export const KnowBtn = styled.button`
  cursor: pointer;
  border: none;
  width: 160px;
  height: 40px;
  background: #11c871;
  border-radius: 4px;
  font-weight: bold;
  color: #ffffff;
`
