import * as React from 'react'

const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#11C871"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m2 3 4.793 4.529a.641.641 0 0 1 0 .943L2 13"
    />
  </svg>
)
export default SvgComponent
