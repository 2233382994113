import * as React from 'react'

const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#595959"
      d="M18.707 8.707a1 1 0 0 0 0-1.414L12.343.929a1 1 0 1 0-1.414 1.414L16.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM1 7a1 1 0 1 0 0 2V7Zm17 0H1v2h17V7Z"
    />
  </svg>
)
export default SvgComponent
