import React, { useEffect, useRef, useState } from 'react'
import LeftIcon from '../../../images/home/banner/left'
import RightIcon from '../../../images/home/banner/right'
import {
  BannerWrap,
  BannerList,
  BannerItem,
  ProgressWrap,
  ProgressItem,
  SwitchBtn,
} from './atom'
// import MainBanner from './mainBanner'
// import StoryBanner from './storyBanner'
import FraudBanner from './fraudBanner'
// import GlobalVipBanner from './globalVipBanner'
// import Preheat618Banner from './preheat618Banner'
// import Discount618Banner from './discount618Banner'
import AgentBanner from './agentBanner'
import RemoteBanner from './remoteBanner'
import SchoolContentWrapper from './schoolOpenBanner'
import BlackMythBanner from './blackMythBanner'
// import MonthVipBanner from './monthVipBanner'

const SpaceTime = 5000 // banner3秒切换一次
const ChangeTime = 30 // 进度条30毫秒变化一次

const Banner = () => {
  const [current, setCurrent] = useState(0) // 当前banner
  const [progress, setProgress] = useState(0) // 进度条宽度
  const timer = useRef(null) // 定时器
  const progressInterval = useRef(null) // 进度条定时器

  // 轮播的banner组件
  const banners = [
    <SchoolContentWrapper />,
    <BlackMythBanner />,
    <RemoteBanner />,
    <AgentBanner />, // 代理商招募
    <FraudBanner />, // 防诈骗
    // <MonthVipBanner />, // 月卡折扣活动
    // <Discount618Banner />, // 618活动
    // <GlobalVipBanner />, // 全球会员
    // <MainBanner />, // 原banner
    // <Preheat618Banner />, // 618预热
    // <StoryBanner />, // 520活动
  ]

  // 3秒后跳下一个
  const startTimer = () => {
    // 切换
    timer.current = setInterval(() => {
      setCurrent(pre => (pre === banners.length - 1 ? 0 : pre + 1))
      setProgress(0)
    }, SpaceTime)
    // 进度条
    progressInterval.current = setInterval(() => {
      setProgress(pre => pre + (ChangeTime / SpaceTime) * 100)
    }, ChangeTime)
  }

  const stopTimer = () => {
    clearInterval(timer.current)
    timer.current = null
    clearInterval(progressInterval.current)
    progressInterval.current = null
    setProgress(0)
  }

  // 切换banner
  const handelSwitch = index => {
    setCurrent(index)
    stopTimer()
    startTimer()
  }

  useEffect(() => {
    startTimer()
    return () => {
      clearInterval(timer.current)
      timer.current = null
      clearInterval(progressInterval.current)
      progressInterval.current = null
    }
  }, [])
  useEffect(() => {
    // 进度条达到最大宽度后重置
    if (progress >= 100) {
      setProgress(0)
    }
  }, [progress])

  return (
    <BannerWrap>
      <BannerList>
        {banners.map((item, index) => (
          <BannerItem key={index} show={index === current}>
            {item}
          </BannerItem>
        ))}
      </BannerList>
      <SwitchBtn
        className="left-btn"
        onClick={() =>
          handelSwitch(current === 0 ? banners.length - 1 : current - 1)
        }
      >
        <LeftIcon />
      </SwitchBtn>
      <SwitchBtn
        className="right-btn"
        onClick={() =>
          handelSwitch(current === banners.length - 1 ? 0 : current + 1)
        }
      >
        <RightIcon />
      </SwitchBtn>
      {/* 进度条 */}
      <ProgressWrap>
        {banners.map((_, index) => (
          <ProgressItem
            key={index}
            className="progress-item"
            progress={progress}
            active={index === current}
            onClick={() => handelSwitch(index)}
          />
        ))}
      </ProgressWrap>
      {/* <BtnList className="btn-list" /> */}
    </BannerWrap>
  )
}

export default Banner
