import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  InfoShareWarpper,
  ItemBox,
  Item,
  ItemMain,
  ItemTitle,
  ItemContent,
  ItemCover,
} from './index.style'
import { H2 } from '../../../styles/index.style'
import CmsContentService from '@raylink/common/utils/getCoverImg'
import { useHref } from '@raylink/common/hooks'
import { jumpTo } from '@raylink/common/utils'

const InfoShare = () => {
  const paramsHref = useHref()
  const { allContentfulNewsPost } = useStaticQuery(graphql`
    query {
      allContentfulNewsPost(
        limit: 3
        filter: { recommend: { eq: true } }
        sort: { order: [DESC], fields: [createTimeInUse] }
      ) {
        edges {
          node {
            id
            title
            slug
            content {
              content
            }
            createTimeInUse
          }
        }
      }
    }
  `)

  return (
    <InfoShareWarpper>
      <H2>RayLink资讯分享</H2>
      <ItemBox>
        {allContentfulNewsPost?.edges?.map(item => (
          <Item
            key={item.node?.id}
            onClick={() => jumpTo(`/news/${item.node?.slug}`)}
          >
            <ItemMain>
              <ItemCover href={paramsHref(`/news/${item.node?.slug}`)}>
                <img
                  className="coverImage"
                  src={CmsContentService.getCoverImg(
                    item.node?.content?.content
                  )}
                  alt={item.node?.title}
                  loading="lazy"
                />
              </ItemCover>
              <ItemTitle href={paramsHref(`/news/${item.node?.slug}`)}>
                {item.node?.title}
              </ItemTitle>
              <ItemContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: CmsContentService.repalceHtml(
                      item.node?.content?.content
                    ).slice(0, 100),
                  }}
                />
              </ItemContent>
            </ItemMain>
          </Item>
        ))}
      </ItemBox>
    </InfoShareWarpper>
  )
}

export default InfoShare
