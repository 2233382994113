import React from 'react'
import { SchoolContentWrapper, BuyButton } from './index.style'
import { BannerContainer, BannerImgBox } from '../atom'
import bannerImg from '../../../../images/home/banner/schoolOpen/banner.webp'
import bannerPadImg from '../../../../images/home/banner/schoolOpen/bannerIpad.webp'
import bannerMobileImg from '../../../../images/home/banner/schoolOpen/bannerMobile.webp'
import mainTitleImg from '../../../../images/home/banner/schoolOpen/title.svg'
import mainTitleMobileImg from '../../../../images/home/banner/schoolOpen/titleMobile.svg'

const SchoolOpenBanner = () => {
  const bannerInfo = {
    title: '',
    alt: '开学季大放价',
    bannerImg,
    bannerPadImg,
    bannerMobileImg,
    mainTitleImg,
    mainTitleMobileImg,
  }

  return (
    <BannerContainer>
      <BannerImgBox>
        <img
          className="web-item"
          src={bannerInfo.bannerImg}
          alt={bannerInfo.title}
          loading="lazy"
        />
        <img
          className="ipad-item"
          src={bannerInfo.bannerPadImg}
          alt={bannerInfo.title}
          loading="lazy"
        />
        <img
          className="mobile-item"
          src={bannerInfo.bannerMobileImg}
          alt={bannerInfo.title}
          loading="lazy"
        />
      </BannerImgBox>
      <SchoolContentWrapper>
        <img
          className="main-title"
          src={bannerInfo.mainTitleImg}
          alt={bannerInfo.alt}
          loading="lazy"
        />
        <img
          className="main-title-mobile"
          src={bannerInfo.mainTitleMobileImg}
          alt={bannerInfo.alt}
          loading="lazy"
        />
        <BuyButton
          target="_blank"
          href="https://www.raylink.live/price.html?from=homepage_banner_bts"
        >
          立即购买
        </BuyButton>
      </SchoolContentWrapper>
    </BannerContainer>
  )
}

export default SchoolOpenBanner
