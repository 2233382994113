import React from 'react'
import qrCode from '../../../../../../images/home/banner/agentQr.png'
import { QrCardWrap } from './atom'

export default function QrCard({ ...rest }) {
  return (
    <QrCardWrap {...rest}>
      <img src={qrCode} alt="QR More" loading="lazy" />
      <div className="qr-text-web">
        <div className="qrMore">更多合作详情</div>
        <div className="scan">请扫码咨询</div>
      </div>
      <div className="qr-text-mobile">
        <div className="qrMore">长按二维码</div>
        <div>咨询合作详情</div>
      </div>
    </QrCardWrap>
  )
}
