import React, { useEffect, useState } from 'react'
import { Modal } from '@raylink/common/components'
// import product from '@raylink/common/components/images/product.jpg'
import {
  UpgradeModalContent,
  Divider,
  KnowBtn,
  // WechatWrapper,
} from './index.style'
import upgradeMessageBg from '../../../images/home/upgrade_bg.png'
import upgradeMessageBgMobile from '../../../images/home/upgrade_bg_mobile.png'

const ModalInfo = () => {
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [closeMessage, setCloseMessage] = useState(false)

  useEffect(() => {
    const startTime = new Date('2023-03-27 18:00:00').getTime()
    const endTime = new Date('2023-03-30 05:00:00').getTime()
    const now = new Date().getTime()
    if (now < endTime && now > startTime && !closeMessage) {
      setShowMessageModal(true)
    }
  }, [showMessageModal])

  const closeModal = event => {
    if (event) event.preventDefault()
    setCloseMessage(true)
    setShowMessageModal(false)
  }

  return (
    <Modal
      simpleHeader
      bodyStyle={{ padding: '0' }}
      visible={showMessageModal}
      closable={false}
      onClose={e => {
        closeModal(e)
      }}
    >
      <UpgradeModalContent className="upgradeMessageModal">
        <img src={upgradeMessageBg} alt="" loading="lazy" />
        <img src={upgradeMessageBgMobile} alt="" loading="lazy" />
        <div>
          <p>尊敬的用户：</p>
          &nbsp;
          <p>
            您好！感谢您一直以来对我们的支持和信任。为了更好地为您提供服务，我们将于近期对服务进行升级维护。具体升级时间为：
            <strong>2023年3月30日凌晨0点至3月30日早上5点</strong>
            。在此期间，系统将无法正常访问，给您带来不便，敬请谅解。
          </p>
          &nbsp;
          <p>
            升级后，我们将为您提供更加优质的服务。如有任何疑问，请随时联系我们。
          </p>
          <p className="phone">
            再次感谢您的支持和信任！
            {/* 如有疑问，请添加
            <strong className='product-show'>
              产品小姐姐微信
              <WechatWrapper>
                <img className="product-img" src={product} alt="" loading="lazy" />
              </WechatWrapper>
            </strong>
            进行咨询。 */}
          </p>
          <Divider style={{ borderTop: '1px solid #F3F6F7' }} />
        </div>
        <KnowBtn
          className="btn"
          onClick={() => {
            closeModal()
          }}
        >
          我知道了
        </KnowBtn>
      </UpgradeModalContent>
    </Modal>
  )
}

export default ModalInfo
