import React from 'react'
import BasicBanner from '../basicBanner'
import { BlackMythWrapper, BuyButton } from './index.style'
import webImg from '../../../../images/home/banner/blackMyth/bannerImg.webp'
import ipadImg from '../../../../images/home/banner/blackMyth/bannerIpadImg.webp'
import mobileImg from '../../../../images/home/banner/blackMyth/bannerMobileImg.webp'
import titleImg from '../../../../images/home/banner/blackMyth/title.svg'
import titleMobileImg from '../../../../images/home/banner/blackMyth/titleMobile.svg'
import cornerImg from '../../../../images/home/banner/blackMyth/cornerMark.svg'

const BlackMythBanner = () => {
  const title = '解锁RayLink超级性能会员，立即远程畅玩黑神话！'
  return (
    <BasicBanner
      webImg={webImg}
      ipadImg={ipadImg}
      mobileImg={mobileImg}
      alt="黑神话悟空"
    >
      <BlackMythWrapper>
        <img className="title-img" src={titleImg} alt={title} loading="lazy" />
        <img
          className="title-mobile-img"
          src={titleMobileImg}
          alt={title}
          loading="lazy"
        />
        <img
          className="corner-img"
          src={cornerImg}
          alt={title}
          loading="lazy"
        />
        <span className="title">{title}</span>
        <BuyButton
          target="_blank"
          href="https://www.raylink.live/price.html?from=homepage_banner_wukong"
        >
          立即购买
        </BuyButton>
      </BlackMythWrapper>
    </BasicBanner>
  )
}

export default BlackMythBanner
