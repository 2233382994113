import { Warpper } from '@raylink/common/components'
import styled from 'styled-components'
import { IndexWrapper } from '../../../view/home/index.style'
import { Media } from '@raylink/common/theme'

export const RiskPreventionIntroWrapper = styled(Warpper)`
  ${IndexWrapper}
`
export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin-top: 56px;
  .content-item {
    width: 380px;
    height: 308px;
    background: linear-gradient(
      100.05deg,
      rgba(245, 252, 254, 0.8) 0%,
      rgba(239, 250, 254, 0.8) 51.92%,
      rgba(230, 247, 252, 0.8) 100%
    );
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px 64px 48px 64px;
    .mobile-img {
      display: none;
    }
    img {
      width: 106px;
      height: 106px;
    }
    .desc-info {
      width: 252px;
      h3 {
        margin: 0;
        font-size: 24px;
        font-weight: 700;
        color: #212121;
      }
      p {
        font-size: 16px;
        color: #595959;
        margin-top: 6px;
      }
    }
  }
  ${Media.ipad`
    width: 928px;
    margin-top: 32px;
    .content-item {
      width: 294px;
      height: 269px;
      img {
        width: 94px;
        height: 94px;
      }
      .desc-info {
        h3 {
          font-size: 22px;
        }
      }
    }
  `}
  ${Media.phone`
    flex-direction: column;
    width: 6.86rem;
    margin-top: 0.48rem;
    border-radius: 0.32rem;
    padding: 0.64rem 0.32rem;
    background: linear-gradient(
      100.05deg,
      rgba(245, 252, 254, 0.8) 0%,
      rgba(239, 250, 254, 0.8) 51.92%,
      rgba(230, 247, 252, 0.8) 100%
    );
    .content-item {
      width: 100%;
      height: auto;
      border-radius: 0;
      flex-direction: row;
      align-items: start;
      padding: 0;
      background: transparent;
      gap: 0.24rem;
      .mobile-img {
        display: block;
        width: 0.8rem;
        height: 0.8rem;
      }
      .normal-img {
        display: none;
      }
      .desc-info {
        width: 4.94rem;
        display: flex;
        flex-direction: column;
        align-items: start;
        text-align: left;
        h3 {
          font-size: 0.4rem;
          line-height: 0.6rem;
        }
        p {
          font-size: 0.32rem;
          line-height: 0.48rem;
        }
      }
      &+.content-item {
        margin-top: 0.48rem;
      }
    }
    
  `}
`
