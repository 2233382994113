import React, { useEffect, useRef, useState } from 'react'
import { TechnologyIntroWrapper, VideoWrapper } from './index.style'
import LowDelayIcon from './images/lowDelay.png'
import HighQualityIcon from './images/color.png'
import FpsIcon from './images/fps.png'
import GameCompare from './images/gameCompare.mp4'
import Modeling from './images/modeling.mp4'
import ColorCompare from './images/colorCompare.mp4'
import { useMedia } from '@raylink/common/hooks'

const TechnologyIntro = () => {
  const title = ['专研远控技术', '追求杰出品质']
  const data = [
    {
      name: '低延迟',
      icon: LowDelayIcon,
      video: Modeling,
      videoTitle: '10ms超低延时',
      videoDesc: '无感延时，媲美本地操作，感受迅捷远程体验',
    },
    {
      name: '高帧率',
      icon: FpsIcon,
      video: GameCompare,
      videoTitle: '144FPS 电竞级帧率',
      videoDesc: '清晰明锐，行云流水，享受精准顺畅的远程操控',
    },
    {
      name: '真色彩',
      icon: HighQualityIcon,
      video: ColorCompare,
      videoTitle: '4:4:4色彩模式',
      videoDesc: '工业级色彩表现，全色域无损传输，享视觉盛宴',
    },
  ]
  const switchTime = 5000
  const isMobile = useMedia()
  const [activeIndex, setActiveIndex] = useState(0)
  const timer = useRef(null)
  const videoRef = useRef(null)
  const videoRefs = useRef([])
  const startTimer = () => {
    timer.current = setInterval(() => {
      setActiveIndex(pre => (pre === data.length - 1 ? 0 : pre + 1))
    }, switchTime)
  }
  const stopTimer = () => {
    clearInterval(timer.current)
    timer.current = null
  }
  useEffect(() => {
    startTimer()
    videoRefs.current.forEach(el => {
      if (!el) return
      el.play()
    })
    if (videoRef.current) {
      videoRef.current.addEventListener('mouseenter', () => {
        stopTimer()
      })
      videoRef.current.addEventListener('mouseleave', () => {
        startTimer()
      })
    }
    return () => {
      stopTimer()
    }
  }, [])
  const switchTab = index => {
    stopTimer()
    setActiveIndex(index)
    startTimer()
  }
  return (
    <TechnologyIntroWrapper activeIndex={activeIndex}>
      {isMobile ? (
        title.map((item, index) => <h2 key={index}>{item}</h2>)
      ) : (
        <h2>{title.join('，')}</h2>
      )}
      <div>
        <div className="tabs">
          {data.map((item, index) => {
            return (
              <div
                className={`tab-item ${index === activeIndex ? 'active' : ''}`}
                key={index}
                onClick={() => switchTab(index)}
              >
                <img src={item.icon} alt={item.name} loading="lazy" />
                <p>{item.name}</p>
              </div>
            )
          })}
        </div>
        <div className="active-border" />
        <div className="line" />
      </div>
      <VideoWrapper activeIndex={activeIndex} ref={videoRef}>
        {data.map((item, index) => {
          return (
            <div className="video-item" key={index}>
              <div className="video-text">
                <span className="title">{item.videoTitle}</span>
                <span className="desc">{item.videoDesc}</span>
              </div>
              <video
                key={index}
                ref={el => {
                  videoRefs.current[index] = el
                }}
                loop
                playsInline
                muted
                loading="lazy"
              >
                <source src={item.video} />
              </video>
            </div>
          )
        })}
      </VideoWrapper>
    </TechnologyIntroWrapper>
  )
}

export default TechnologyIntro
