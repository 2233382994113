import { NewWrapper } from '@raylink/common/components'
import styled from 'styled-components'
import { IndexWrapper } from '../../../view/home/index.style'
import { color, Media } from '@raylink/common/theme'

export const SceneIntroWrapper = styled(NewWrapper)`
  ${IndexWrapper}
  background: linear-gradient(180deg, #fff 1.56%, rgba(255, 255, 255, 0) 26.12%),
  linear-gradient(
    90deg, 
    rgba(216, 245, 234, 0.6) 0%, 
    rgba(241, 249, 253, 0.6) 51.84%, 
    rgba(219, 240, 255, 0.6) 99.69%
    );
  width: 100%;
  height: 706px;
  .bottom-switch {
    position: absolute;
    bottom: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 32px;
    .disabled {
      cursor: not-allowed;
      path {
        fill: rgba(172, 172, 172);
      }
    }
  }
  ${Media.ipad`
    height: 528px;
    .bottom-switch {
      bottom: 56px;
    }
  `}
  ${Media.phone`
    height: 11.64rem;
    .bottom-switch {
      display: none;
    }
  `}
`

export const ContentWrapper = styled.div`
  width: 1230px;
  margin-left: 30px;
  overflow: hidden;
  margin-top: 64px;
  position: relative;
  ${Media.ipad`
    width: 928px;
    margin-top: 32px;
    margin-left: 0;
  `}
  ${Media.phone`
    width: 100%;
    height: 100%;
    margin-left: 0;
  `}
`

export const Content = styled.div`
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 32px;
  transition: transform 0.3s ease-in-out;
  transform: ${({ activeIndex }) =>
    `translateX(-${activeIndex * 378 + activeIndex * 32}px)`};
  .content-item {
    width: 378px;
    height: 392px;
    border-radius: 12px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
    cursor: pointer;
    .img {
      width: 100%;
      height: 284px;
    }
    .desc-info {
      height: 160px;
      margin-top: -52px;
      .blur {
        height: 40px;
        backdrop-filter: blur(12px);
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.8) 0%,
          #fff 100%
        );
        box-shadow: 0px 0px 40px 0px rgba(69, 96, 255, 0.04);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
      .text {
        background: #fff;
        width: 100%;
        height: 392px;
        padding: 0 36px;
        transition: all 0.5s;
        position: relative;
        h3 {
          margin: 0;
          font-size: 28px;
          color: #212121;
        }
        p {
          font-size: 20px;
          color: #595959;
          margin-top: 8px;
          font-weight: 400;
        }
      }
    }
    &:hover {
      box-shadow: 0px 24px 30px 0px rgba(204, 204, 204, 0.16);
      .blur {
        height: 0;
      }
      .desc-info {
        .text {
          transform: translateY(-238px);
          padding-top: 48px;
          .detail-item {
            display: block;
          }
        }
      }
    }
  }
  ${Media.ipad`
    width: 928px;
    gap: 20px;
    transform: ${({ activeIndex }) =>
      `translateX(-${activeIndex * 295 + activeIndex * 20}px)`};
    .content-item {
      width: 295px;
      height: 303px;
      .img {
        height: 221px;
      }
      .desc-info {
        height: 124px;
        margin-top: -42px;
        .blur {
          height: 28px;
        }
        .text {
          padding: 0 18px;
          h3 {
            font-size: 26px;
          }
          p {
            font-size: 16px;
          }
          .detail-item {
            .line {
              margin: 20px 0;
            }
            ul li {
              img {
                width: 18px;
                height: 14px;
              }
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  `}
  ${Media.phone`
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 0.12rem;
    gap: 0.24rem;
    .content-item {
      width: 6.86rem;
      height: 1.72rem;
      background: #fff;
      display: flex;
      flex-direction: row;
      .img {
        width: 2.3rem;
        height: 1.72rem;
      }
      .desc-info {
        margin-top: 0;
        .blur {
          display: none;
        }
        .text {
          height: 1.72rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          h3 {
            font-size: 0.4rem;
            line-height: 0.6rem;
          }
          p {
            font-size: 0.28rem;
            line-height: 0.42rem;
          }
        }
      }
      &:hover {
        .desc-info {
          .text {
            transform: translateY(0);
            padding-top: 0;
          }
        }
      }
    }
  `}
`

export const SwitchWrapper = styled.div`
  height: 48px;
  width: 48px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    path {
      fill: ${color.primary};
    }
  }
`
export const ItemDetail = styled.div`
  .detail-item {
    display: none;
    .line {
      height: 1px;
      width: 100%;
      background: ${color.primary};
      margin: 32px 0;
    }
    ul li {
      display: flex;
      align-items: center;
      gap: 10px;
      white-space: nowrap;
      margin-bottom: 16px;
      img {
        width: 22px;
        height: 17px;
      }
      span {
        font-size: 18px;
        font-weight: 400;
        color: #353535;
      }
    }
  }
  .card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 160px;
    z-index: -1;
  }
  ${Media.phone`
    display: none;
  `}
`
