import { color, Media } from '@raylink/common/theme'
import { css } from 'styled-components'

export const IndexWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  h2 {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    font-family: Source Han Sans CN;
    color: ${color.textBlack};
  }
  .desc-text {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: ${color.primary};
    margin-top: 8px;
    gap: 8px;
    cursor: pointer;
    &:hover .img {
      transform: translateX(8px);
    }
    .img {
      margin-top: 2px;
      transition: all 0.3s;
    }
  }
  ${Media.ipad`
    margin-top: 56px;
    h2 {
      font-size: 34px;
    }
  `}
  ${Media.phone`
    font-size: 0.56rem;
    line-height: 0.84rem;
    margin-top: 0.8rem;
    h2 {
      font-size: 0.56rem;
    }
    .desc-text {
      margin-top: 0.08rem;
      span {
        line-height: 1;
      }
    }
  `}
`
