import styled from 'styled-components'
import { Media } from '@raylink/common/theme'
import { NewWrapper } from '@raylink/common/components'

export const BannerContainer = styled.div`
  font-family: 'Source Han Sans CN';
  position: relative;
  width: 100%;
  height: 720px;
  .mobile-item {
    display: none;
  }
  .ipad-item {
    display: none;
  }
  ${Media.ipad`
    height: 680px;
    .mobile-item {
      display: none;
    }
    .web-item {
      display: none;
    }
    .ipad-item {
      display: block;
    }
  `}
  ${Media.phone`
    height: 12.48rem;
    .mobile-item {
      display: block;
    }
    .web-item {
      display: none;
    }
    .ipad-item {
      display: none;
    }
  `}
`
export const BannerImgBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background: linear-gradient(
    99.94deg,
    #fcfffd -8.24%,
    #e5fff2 54.67%,
    #d6ffef 105.25%
  );
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
export const Right = styled.div`
  img {
    width: 550x;
    height: 380px;
  }
  ${Media.ipad`
    img {
      width: 450px;
      height: 311px;
    }
  `}
  ${Media.phone`
    display: none;
  `}
`
export const BannerContentWrapper = styled(NewWrapper)`
  padding-top: 172px;
  display: flex;
  ${Media.ipad`
    padding-top: 161px;
  `}
  ${Media.phone`
    padding-top: 1.96rem;
  `}
`
export const H1 = styled.div`
  width: fit-content;
  font-size: 46px;
  font-weight: 700;
  line-height: 64px;
  margin: 0;
  color: #212121;
  white-space: nowrap;
  span {
    background: linear-gradient(90deg, #002b36 0%, #62c811 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    color: transparent;
  }
  ${Media.ipad`
    font-size: 42px;
    line-height: 59px;
  `}
  ${Media.phone`
    font-size: 0.56rem;
    line-height: 0.78rem;
  `}
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  .last-title {
    margin: 3px 0 20px;
  }
  ${Media.ipad`
    padding-left: 0;
    .last-title {
      margin: 8px 0 24px;
    }
  `}
  ${Media.phone`
    width: fit-content;
    margin: 0 auto;
    padding: 0 0.32rem;
    .last-title {
      margin: 0.08rem 0 0.32rem;
    }
  `}
`
export const ReasonsWrapper = styled.div`
  .qrCodeImg {
    width: 100px;
  }
  ${Media.ipad`
    .qrCodeImg {
      width: 80px;
    }
  `}
  ${Media.phone`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .qrCodeImg {
      width: 1.6rem;
    }
  `}
`

export const ReasonsList = styled.div`
  width: 475px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;
  margin-bottom: 20px;
  ${Media.ipad`
    width: 461px;
  `}
  ${Media.phone`
    width: fit-content;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 0.12rem;
    margin-bottom: 0;
  `}
`
export const ReasonsItem = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  font-size: 16px;
  line-height: 1.5;
  img {
    width: 15px;
    height: 15px;
  }
  ${Media.phone`
    gap: 0.22rem;
    font-size: 0.32rem;
    img {
      width: 0.3rem;
      height: 0.3rem;
    }
  `}
`
export const QrWrapper = styled.div`
  width: fit-content;
  position: relative;
  padding: 2px 2px 0;
  border-radius: 10px;
  background: #fff;
  .card {
    position: absolute;
    bottom: 1000px;
    left: 138px;
  }
  &:hover {
    .card {
      bottom: 6px;
    }
  }
  ${Media.ipad`
    .card {
      left: 110px;
    }
    &:hover {
      .card {
        bottom: 4px;
      }
    }
  `}
  ${Media.phone`
    padding: 0.04rem 0.04rem 0;
    border-radius: 0.2rem;
    .card {
      display: none;
    }
  `}
`
