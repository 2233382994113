import { DownLoadBarBox, DownLoadWarpper, DownLoadTitle } from './index.style'
import React from 'react'
import Logo from './images/logo.svg'
import GroupBtn from '../banner/remoteBanner/components/groupBtn'
import Union from './images/slogan.svg'

const DownloadBar = () => {
  return (
    <DownLoadBarBox>
      <DownLoadWarpper>
        <img className="logo" src={Logo} alt="RayLink Logo" />
        <DownLoadTitle>
          <img src={Union} alt="" loading="lazy" />
        </DownLoadTitle>
        <GroupBtn />
      </DownLoadWarpper>
    </DownLoadBarBox>
  )
}

export default DownloadBar
