import styled from 'styled-components'
import { Warpper } from '@raylink/common/components'
import { IndexWrapper } from '../../../view/home/index.style'
import { Media } from '@raylink/common/theme'

export const FunctionIntroWrapper = styled(Warpper)`
  ${IndexWrapper}
  position: relative;
  width: 100%;
  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  ${Media.phone`
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 14.26%),
    linear-gradient(90deg, rgba(216, 245, 234, 0.6) 0%, rgba(241, 249, 253, 0.6) 51.84%, rgba(219, 240, 255, 0.6) 99.69%);
    width: 100%;
    padding-bottom: 0.8rem;
    .bg-img {
      display: none;
    }
  `}
`

export const ContentWrapper = styled.div`
  display: flex;
  gap: 142px;
  margin: 50px 0;
  .card-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px;
    padding: 32px 40px 32px 32px;
    width: 464px;
    position: relative;
    cursor: pointer;
    border-radius: 12px;
    transition: height 0.3s;
    .title {
      font-size: 24px;
      font-weight: 700;
      color: #212121;
      margin: 0;
    }
    .description {
      font-size: 16px;
      font-weight: 400;
      color: #595959;
      margin-top: 4px;
      white-space: nowrap;
    }
    .item-bg {
      position: absolute;
      right: 0px;
      top: 0;
      width: 94px;
      height: 67px;
    }
  }
  .active {
    height: 128px;
    border: 2px solid #fff;
    background: linear-gradient(
        274.35deg,
        #f5fffa 3.5%,
        rgba(255, 255, 255, 0.5) 21%
      ),
      #fff;
    box-shadow: 0px 24px 24px 0px rgba(199, 199, 199, 0.08);
  }
  .img {
    height: 445px;
    width: 586px;
  }
  ${Media.ipad`
    margin: 40px 0;
    .card-item {
      width: 340px;
      height: 81px;
      .title {
        font-size: 22px;
      }
      .description {
        width: 276px;
        white-space: normal;
      }
    }
    .active {
      height: 131px;
    }
    .img {
      width: 491.54px;
      height: 374px;
    }
  `}
  ${Media.phone`
    margin: 0.4rem 0;
    .card-item {
      width: 6.54rem;
      height: 1.56rem;
      background: #fff;
      border-radius: 0.22rem;
      margin-bottom: 0.24rem;
      padding: 0.48rem;
      .title {
        font-size: 0.4rem;
        line-height: 0.6rem;
      }
      .description {
        font-size: 0.32rem;
        line-height: 0.48rem;
      }
      .item-img {
        width: 5.58rem;
        height: 3.84rem;
        margin-top: 0.48rem;
        border-radius: 0.2rem;
      }
    }
    .active {
      height: 6.96rem;
    }
  `}
`
