import { NewWrapper } from '@raylink/common/components'
import styled from 'styled-components'
import { IndexWrapper } from '../../../view/home/index.style'
import {
  color,
  fillBtnStyle,
  lineBorderBtnStyle,
  Media,
} from '@raylink/common/theme'

export const BusinessIntroWrapper = styled(NewWrapper)`
  ${IndexWrapper}
`

export const ContentWrapper = styled(NewWrapper)`
  margin-top: 56px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 24px;
  .item1 {
    grid-column: 1 / span 2;
    grid-row: 1;
    height: 292px;
    ul {
      width: 440px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .item2 {
    grid-column: 1;
    grid-row: 2;
    height: 316px;
    ul {
      grid-template-columns: repeat(2, 1fr);
    }
    .desc {
      width: 248px;
    }
  }
  .item3 {
    grid-column: 2;
    grid-row: 2;
    height: 316px;
    ul {
      grid-template-columns: repeat(1, 1fr);
    }
    .desc {
      width: 248px;
    }
  }
  .content-item {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .desc-info {
      position: absolute;
      top: 48px;
      left: 48px;
      h3 {
        margin: 0;
        font-size: 24px;
        font-weight: 700;
        color: ${color.textBlack};
      }
      .desc {
        font-size: 16px;
        font-weight: 400;
        color: #353535;
        margin-top: 8px;
      }
      ul {
        display: grid;
        margin-top: 40px;
        gap: 20px 24px;
        li {
          display: flex;
          align-items: center;
          gap: 4px;
          white-space: nowrap;
          img {
            width: 20px;
            height: 20px;
          }
          span {
            font-size: 16px;
            font-weight: 400;
            color: ${color.textDark};
          }
        }
      }
    }
  }
  .bg {
    display: block;
  }
  .bg-ipad {
    display: none;
  }
  .bg-mobile {
    display: none;
  }
  ${Media.ipad`
    width: 928px;
    margin-top: 32px;
    gap: 16px;
    .item1, .item2, .item3 {
      height: 257px;
    }
    .item1 ul {
      width: 424px;
    }
    .item2 ul {
      width: 248px;
    }
    .content-item {
      .desc-info {
        width: 284px;
        top: 39px;
        left: 40px;
        ul {
          margin-top: 24px;
          gap: 16px;
        }
      }
      .bg {
        display: none;
      }
      .bg-ipad {
        display: block;
      }
      .bg-mobile {
        display: none;
      }
    }
  `}
  ${Media.phone`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0.48rem;
    .item1, .item2, .item3 {
      width: 6.86rem;
    }
    .item1 {
      height: 8.7rem;
      ul {
        width: 5.76rem;
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .item2 {
      height: 5.94rem;
    }
    .item3 {
      height: 4.48rem;
    }
    .item2, .item3 {
      ul {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .content-item {
      border-radius: 0.32rem;
      .desc-info {
        top: 0.64rem;
        left: 0.56rem;
        width: 5.68rem;
        height: 1.68rem;
        h3 {
          font-size: 0.4rem;
          line-height: 0.6rem;
        }
        p {
          font-size: 0.32rem;
          line-height: 0.48rem;
        }
        ul {
          gap: 0.22rem 0.24rem;
          margin-top: 0.32rem;
          li {
            span {
              line-height: 0.48rem;
              white-space: nowrap;
            }
          }
        }
      }
      .bg {
        display: none;
      }
      .bg-ipad {
        display: none;
      }
      .bg-mobile {
        display: block;
        width: 100%;
      }
    }
  `}
`

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 56px;
  gap: 16px;
  ${Media.ipad`
    margin-top: 40px;
  `}
  ${Media.phone`
    margin-top: 0.42rem;
    gap: 0.32rem;
  `}
`

export const Button = styled.a`
  ${({ type }) => (type === 'fill' ? fillBtnStyle : lineBorderBtnStyle)}
  width: 160px;
  height: 48px;
  font-size: 16px;
`
