import styled from 'styled-components'
import { Media } from '@raylink/common/theme'

export const QrModalContent = styled.div`
  ${Media.phone`
    width: fit-content;
    position: relative;
    .close-icon {
      position: absolute;
      top: -0.6rem;
      left: 4.1rem;
    }
  `}
`
