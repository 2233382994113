import * as React from 'react'

const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#595959"
      strokeLinecap="round"
      strokeWidth={2}
      d="m6.03 1.03 6.263 6.263a1 1 0 0 1 0 1.414L6.03 14.97"
      opacity={0.4}
    />
  </svg>
)
export default SvgComponent
