import { NewWrapper } from '@raylink/common/components'
import { fillBtnStyle, Media } from '@raylink/common/theme'
import styled from 'styled-components'

export const BlackMythWrapper = styled(NewWrapper)`
  position: absolute;
  top: 172px;
  display: flex;
  flex-direction: column;
  align-items: start;
  .title-img {
    width: 470.66px;
    height: 172.98px;
  }
  .title-mobile-img {
    display: none;
  }
  .corner-img {
    margin-top: -46px;
    width: 123px;
    height: 51px;
  }
  .title {
    color: #fff;
    font-family: 'Alibaba PuHuiTi';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 16px;
  }
  ${Media.ipad`
    top: 130px; 
  `}
  ${Media.phone`
    align-items: center;
    top: 1.98rem;
    .title-mobile-img {
      display: block;
      width: 6.82rem;
      height: 2.5rem;
    }
    .title-img {
      display: none;
    }
    .corner-img {
      margin-top: 0.2rem;
      margin-left: -3.7rem;
      width: 1.94rem;
      height: 0.76rem;
    }
    .title {
      width: 5.3rem;
      font-size: 0.4rem;
      text-align: center;
      margin-top: 0;
    }
  `}
`

export const BuyButton = styled.a`
  ${fillBtnStyle}
  font-size: 18px;
  width: 184px;
  height: 48px;
  margin-top: 43px;
  border-radius: 24px;
  border: 1px solid #b4844c;
  background: linear-gradient(180deg, #f8e37a 12.91%, #b5854d 100%);
  box-shadow: 0px 4px 10.2px 0px rgba(134, 180, 194, 0);
  backdrop-filter: blur(16px);
  color: #12110f;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background: linear-gradient(180deg, #b5854d 0%, #f8e37a 87.09%);
    box-shadow: 0px 4px 10.2px 0px rgba(134, 180, 194, 0);
  }
  ${Media.phone`
    width: 3.44rem;
    height: 0.88rem;
    border-radius: 0.48rem;
    font-size: 0.36rem;
    margin-top: 0.72rem;
  `}
`
