import styled from 'styled-components'
import { Media, color } from '@raylink/common/theme'
import { NewWrapper } from '@raylink/common/components'

export const BannerContentWrapper = styled(NewWrapper)`
  position: absolute;
  top: 157px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-right: 50px;
  ${Media.ipad`
    padding-right: 0;
  `}
  ${Media.phone`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 11.52rem;
    top: 0.46rem;
    padding-right: 0;
  `}
`

export const Left = styled.div`
  width: 392px;
  height: 372px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  top: 174px;
  .title {
    background-image: linear-gradient(to right, #11c871 0%, #42afd7 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    width: fit-content;
    margin-bottom: 10px;
  }
  .description {
    font-size: 16px;
    font-weight: 400;
    color: ${color.textDark};
    margin-top: 12px;
  }
  .button-list {
    margin-top: 32px;
  }
  ${Media.ipad`
    height: 288px;
    .title {
      font-size: 16px;
    }
    .description {
      margin-top: 12px;
    }
  `}
  ${Media.phone`
    width: 100%;
    height: auto;
    padding: 0 0.32rem;
    align-items: center;
    .title {
      font-size: 0.32rem;
    }
    .description {
      margin-top: 0.16rem;
      font-size: 0.32rem;
      width: 5.12rem;
      text-align: center;
    }
    .button-list {
      margin-top: 0.48rem;
    }
  `}
`
export const Right = styled.div`
  .img {
    width: 692px;
    height: 417px;
  }
  ${Media.ipad`
    .img {
      width: 538px;
      height: 339px;
    }
  `}
  ${Media.phone`
    .img {
      width: 6.82rem;
      height: 4.14rem;
      margin: 0.32rem 0 0 -0.52rem;
    }
  `}
`
export const H1 = styled.h1`
  font-size: 56px;
  line-height: 70px;
  font-weight: 700;
  margin: 0;
  color: ${color.textBlack};
  ${Media.ipad`
    font-size: 48px;
    line-height: 60px;
  `}
  ${Media.phone`
    font-size: 0.72rem;
    line-height: 0.9rem;
  `}
`
