import React from 'react'
import ModalMask from '@raylink/common/components/modalMask'
import CloseIcon from '../../../../../../images/home/banner/closeIcon.svg'
import QrCard from '../qrCard'
import { QrModalContent } from './atom'

export default function QrModal({ setOpen, ...rest }) {
  return (
    <ModalMask {...rest}>
      <QrModalContent>
        <img
          src={CloseIcon}
          alt=""
          loading="lazy"
          className="close-icon"
          onClick={() => setOpen(false)}
        />
        <QrCard />
      </QrModalContent>
    </ModalMask>
  )
}
