import styled from 'styled-components'
import { Media } from '@raylink/common/theme'
import { NewWrapper } from '@raylink/common/components'
import { color } from '@raylink/common//theme/variables'
import BgImgUrl from './images/bg.png'
import BgImgUrl2 from './images/desktop-bg.png'

export const DownLoadBarBox = styled.div`
  width: 100%;
  height: 355px;
  margin-top: 58px;
  background: url(${BgImgUrl}) no-repeat top center / auto 100%;
  background-size: cover;
  background-position: center center;
  ${Media.desktopContact`
    background: url(${BgImgUrl2}) no-repeat top center / auto 100%;
    background-size: 100% 100%;
  `}
  ${Media.ipad`
    margin-top: 36px;
  `}
  ${Media.phone`
    height: 4.8rem;
    margin-top: 0.8rem;
  `}
`

export const DownLoadWarpper = styled(NewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100%;
  margin: 0 auto;
  padding-top: 78px;
  .logo {
    width: 188px;
    height: 39.52px;
  }
  ${Media.ipad`
    width: calc(100% - 54px);
  `}
  ${Media.phone`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0.2rem;
    .logo {
      width: 3.26rem;
      height: 0.68rem;
    }
  `}
`
export const DownLoadTitle = styled.div`
  img {
    width: 432.39px;
    height: 78.9px;
    margin: 8px 0 15px 0;
  }
  ${Media.phone`
    img {
      width: 6.58rem;
      height: 1.2rem;
      margin: 0.1rem 0 0.3rem 0;
    }
  `}
`

export const DownLoadBtn = styled.div`
  width: 176px;
  height: 54px;
  font-size: 18px;
  font-weight: 500;
  line-height: 54px;
  text-align: center;
  border-radius: 6px;
  color: ${color.primary};
  border: 1px solid ${color.border};
  background: ${color.base};
  cursor: pointer;
  ${Media.phone`
    width: 2.6rem;
    height: 0.88rem;
    font-size: 0.32rem;
    line-height: 0.88rem;
  `}
`
